import React, { useContext } from 'react'
import { useGoogleLogin } from 'react-use-googlelogin'

const GoogleAuthContext = React.createContext({
    signIn: () => { },
    isSignedIn: false,
    isInitialized: false,
    googleUser: {},
    signOut: () => { },
    fetchWithRefresh: () => { },
})

export const useGoogleAuth = () => {
    const contextValue = useContext(GoogleAuthContext)
    if (contextValue === undefined) {
        throw new Error('useGoogleAuth must be inside a GoogleAuthProvider with a value')
    }
    return contextValue
}

export const GoogleAuthProvider = ({ children }) => {
    const {
        googleUser,
        isInitialized,
        grantOfflineAccess,
        signOut,
        isSignedIn,
        refreshUser,
    } = useGoogleLogin({
        clientId: process.env.GATSBY_GOOGLE_CLIENT_ID,
    })

    /**
     * A wrapper function around `fetch` that handles automatically refreshing
     * our `accessToken` if it is within 5 minutes of expiring.
     *
     * Behaves identically to `fetch` otherwise.
     */
    const fetchWithRefresh = async (input, init) => {
        let accessToken = googleUser.accessToken
        // The token is within 5 minutes of expiring
        const shouldRefreshToken =
            googleUser.expiresAt - 300 * 1000 - Date.now() <= 0

        if (shouldRefreshToken) {
            const tokenObj = await refreshUser()
            accessToken = tokenObj?.accessToken ?? accessToken
    }

        return fetch(input, {
            ...init,
            headers: {
                ...init?.headers,
                Authorization: `Bearer ${accessToken}`,
            },
        })
    }

    return (
        <GoogleAuthContext.Provider
            value={{
                signIn: grantOfflineAccess,
                isSignedIn,
                isInitialized,
                googleUser,
                signOut,
                fetchWithRefresh,
            }}
        >
            {children}
        </GoogleAuthContext.Provider>
    )
}
